// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'admin-lte/dist/css/adminlte.css'
import "@fortawesome/fontawesome-free/css/all"
var jQuery = require("jquery");

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require('admin-lte');
//import "admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js"

// font part
import "@fortawesome/fontawesome-free/js/all.min"
//import "chart.js/dist/Chart.min.js"
//import "admin-lte/plugins/uplot/uPlot.iife.min.js"
//import "admin-lte/plugins/datatables/jquery.dataTables.min.js"


Rails.start()
Turbolinks.start()
ActiveStorage.start()
require("trix")
require("@rails/actiontext")